<template>
    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1 dmr">
                <div class="mini_fram">
                    <leisure-game-ifram-view>
                        <iframe id="nextgame-gamefram" name="stream_frm" scrolling="no" frameborder="0"
                                src="https://nextgames.co.kr/ring/live">
                        </iframe>
                    </leisure-game-ifram-view>
                </div>
            </div>
            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>Next 1분링 </span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg">
                            <span class="t">1분링</span>
                            <div class="mg_btn b4 bg-grey"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL,leisureGameInfo.leisureGame.id,1, normalConfig.odds1, normalConfig, '2x')">
                                <div class="r">
                                    <span class="n">2x</span>
                                    <span class="b">{{normalConfig.odds1}}</span>
                                </div>
                            </div>

                            <div class="mg_btn b4 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL && selectedInfo.selectedWay ===2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL,leisureGameInfo.leisureGame.id,2,  normalConfig.odds2, normalConfig, '3x')">
                                <div class="r">
                                    <span class="n">3x</span>
                                    <span class="b">{{normalConfig.odds2}}</span>
                                </div>
                            </div>

                            <div class="mg_btn b4 bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL && selectedInfo.selectedWay ===3}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL,leisureGameInfo.leisureGame.id,3,  normalConfig.odds3, normalConfig, '5x')">
                                <div class="r">
                                    <span class="n">5x</span>
                                    <span class="b">{{normalConfig.odds3}}</span>
                                </div>
                            </div>

                            <div class="mg_btn b4 bg-yellow"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL && selectedInfo.selectedWay ===4}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL,leisureGameInfo.leisureGame.id,4,  normalConfig.odds4, normalConfig, '50x')">
                                <div class="r">
                                    <span class="n">50x</span>
                                    <span class="b">{{normalConfig.odds4}}</span>
                                </div>
                            </div>


                        </div>
                    </div>

                    <!--베팅카트-->
                    <leisure-bet-cart-comp
                            ref="betCart" :kind="kind"
                            :selected-info="selectedInfo">
                        Next 1분링
                    </leisure-bet-cart-comp>


                </div>
            </div>
            <div class="m3">

                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>
            </div>



        </div>
    </div>
</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import {getLeisureGameAttributeConfig, getLotusToken} from "../../../network/leisureRequest";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "NextRing1m",
        mixins: [leisureGameMixin],
        components: {
            LeisureCompGameLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_NEXT_RING1M,
            }
        },

        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data
                        //배당설정
                        this.normalConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NEXT_RING1M_NOMAL)
                        })
                    }
                })
            },

        },
        created() {
            this.initKindConfig(this.kind)
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_NEXT);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
        },
        beforeDestroy() {
        }
    }
</script>

<style scoped>

</style>